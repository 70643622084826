import { GridComparatorFn } from "@mui/x-data-grid-premium";
import dayjs from "dayjs";
import firebase from "firebase/app";
import { Booking, CampaignType, Platform, Prices, Talent, TimestampOrDate, TimeUnits } from '../shared/types';
const numAbbr = require('number-abbreviate');
const axios = require('axios');
const equal = require('deep-equal');

function getCurrencySymbol(currencyCode: string) {
    if (currencyCode) {
        const cur = currencyCode.toLowerCase();
        if (cur === 'usd' || cur === 'us') {
            return '$';
        } else if (cur === 'gbp' || cur === 'gb') {
            return '£';
        } else {
            return '$';
        }
    } else {
        return '';
    }
}

function abbreviateNumber(num: number) {
    if (!num) {
        return 0;
    }

    let newNum = numAbbr(num, 1);
    if (typeof newNum === 'string') {
        return newNum.toUpperCase();
    }
    return newNum;
}

async function getExchangeRate(from = 'GBP', to = 'USD') {
    const defaultRate = 1.22;

    try {
        // const theDate = new Date(); // using fake 'h' param to force request to not cache
        const response = await axios.get(
            `https://open.er-api.com/v6/latest/GBP`
            // `https://api.exchangerate.host/convert?from=${from}&to=${to}&h=${theDate.toISOString()}`,
        );

        if (typeof (response?.data?.rates?.USD) === 'number') {
            console.log(`Got rate`, response?.data?.rates?.USD);
            return response?.data?.rates?.USD as number;
        } else {
            console.error(`Exchange Rate Error; using default ${defaultRate}`);
            return defaultRate;
        }
    } catch (error) {
        // console.error(error);
        console.error(`Exchange Rate Error; using default ${defaultRate}`);
        return defaultRate;
    }
}

function getPrice(talent: Talent, poundToDollar: number, campaignType: CampaignType, userCurrency: string) {
    function getLocalPrice(price: number, currencyFrom: string, currencyTo: string, roundTo = 25) {
        if (currencyFrom === currencyTo) {
            return price;
        } else if (currencyFrom === 'USD' && currencyTo === 'GBP') {
            let convertedPrice = price * (1 / poundToDollar);
            return Math.ceil(convertedPrice / roundTo) * roundTo; // https://stackoverflow.com/questions/43890561/how-to-round-number-to-the-closest-50-in-javascript
        } else if (currencyFrom === 'GBP' && currencyTo === 'USD') {
            let convertedPrice = price * poundToDollar;
            return Math.ceil(convertedPrice / roundTo) * roundTo;
        }
    }

    if (campaignType === "Song") {
        return getLocalPrice(talent.songPrice!, talent.currency!, userCurrency);
    } else {
        return getLocalPrice(talent.brandPrice!, talent.currency!, userCurrency);
    }
}

function getLocalPrices(talent: Talent, poundToDollar: number, userCurrency: string) {
    function getLocalPrice(price: number, currencyFrom: string, currencyTo: string, roundTo = 25) {
        if (currencyFrom === currencyTo) {
            return price;
        } else if (currencyFrom === 'USD' && currencyTo === 'GBP') {
            let convertedPrice = price * (1 / poundToDollar);
            return Math.ceil(convertedPrice / roundTo) * roundTo; // https://stackoverflow.com/questions/43890561/how-to-round-number-to-the-closest-50-in-javascript
        } else if (currencyFrom === 'GBP' && currencyTo === 'USD') {
            let convertedPrice = price * poundToDollar;
            return Math.ceil(convertedPrice / roundTo) * roundTo;
        }
    }

    let localPrices: Prices = {};

    if (talent.prices) {
        for (let priceEntry of Object.entries(talent.prices)) {
            localPrices[priceEntry[0] as keyof Prices] = getLocalPrice(priceEntry[1], talent.currency!, userCurrency);
        }
    }

    return localPrices;
}

function getDefaultGuidePrice(priceObj: Prices | undefined, platform: Platform, campaignType: CampaignType) {
    priceObj = priceObj || {};

    if (campaignType === "Song") {
        return priceObj["songPrice" + platform as keyof Prices] || 0;
    } else {
        if (platform === "TikTok" && priceObj.brandPriceTikTok) {
            return priceObj.brandPriceTikTok;
        } else if (platform === "Instagram" && priceObj.brandPriceInstagramReel) {
            return priceObj.brandPriceInstagramReel;
        } else if (platform === "YouTube" && priceObj.brandPriceYouTubeIntegration) {
            return priceObj.brandPriceYouTubeIntegration;
        } else {
            return 0;
        }

        // const priceKey = Object.keys(priceObj).filter((key) => key.includes(platform) && !key.includes("song"))[0];
        // if (priceKey) {
        //     return priceObj[priceKey as keyof Prices] || 0;
        // } else {
        //     return 0;
        // }
    }
}


function isSongPriceDomain(domain: string) {
    const isSongPriceDomain = [
        'theorchard.com',
        'getengagedmedia.com',
        'flight.house',
        'vrtcl.video',
        'umusic.com',
        'thisisround.co.uk',
        'homemadeprojects.com',
        'test.com',
        'atggroup.io',
        'parlophonemusic.com',
        'bytedance.com',
        'atlanticrecords.co.uk',
        'sonymusic.com',
        'jonvinyl.com',
        'ncgrey.com',
        'nuitsansf.in',
        'black-butter.co.uk',
        'lunchmoneymusic.co.uk',
        'warnerrecords.com',
        'ministryofsoundrecords.com',
        'happystudios.xyz',
        '5krecords.com',
        'maddecentpub.com',
        'we-generate.com',
        'lightning.agency',
        'spinninrecords.nl',
        'spinninrecords.com',
        'audioclout.com',
        'threesixzero.com',
        'aristarecordings.com',
        'the-trenches.com',
        'creedmedia.com',
        'untitledrecs.com',
        'onerpm.com',
        'trndsttrs.media',
        'amethystcollab.com',
        'empi.re',
        'streamstrudel.com'
    ].includes(domain.toLowerCase());

    // console.log(`isSongPriceDomain`, isSongPriceDomain);

    return isSongPriceDomain;
}

function removeParamsFromURL(url: string) {
    return url.split('?')[0];
}

// date1: Date, date2: Date, type: ''
function compareDates(date1: TimestampOrDate, date2: TimestampOrDate, type: TimeUnits) {
    let cleanDate1: Date;
    let cleanDate2: Date;

    if (date1 instanceof firebase.firestore.Timestamp) {
        cleanDate1 = (date1 as firebase.firestore.Timestamp).toDate();
    } else {
        cleanDate1 = date1;
    }

    if (date2 instanceof firebase.firestore.Timestamp) {
        cleanDate2 = (date2 as firebase.firestore.Timestamp).toDate();
    } else {
        cleanDate2 = date2;
    }

    return dayjs(cleanDate1).diff(cleanDate2, type);
}

function deepEqual(obj1: unknown, obj2: unknown) {
    return equal(obj1, obj2);
}

function createMonthOptions(monthsPrevious = 12, monthsFuture = 12) {
    const options = [];

    // Greater than or equal to, so we include this month...
    for (let m = monthsPrevious; m >= 0; m--) {
        const option = dayjs().subtract(m, 'month').format("MMM 'YY");
        options.unshift(option)
    }

    for (let m = 1; m <= monthsFuture; m++) {
        const option = dayjs().add(m, 'month').format("MMM 'YY");
        options.unshift(option);
    }

    return options;
}

function getCurrentMonthDue() {
    return dayjs().set('date', 15).toDate();
}

function debounce(fn: Function, ms = 300) {
    let timeoutId: ReturnType<typeof setTimeout>;
    // console.log("DEBOUNCE OUTER");
    return function (this: any, ...args: any[]) {
        clearTimeout(timeoutId);
        timeoutId = setTimeout(() => fn.apply(this, args), ms);
        // console.log("DEBOUNCE INNER");
    };
};

const sortMonthOptions: GridComparatorFn<Booking['monthDue']> = (monthOpt1: Booking['monthDue'], monthOpt2: Booking['monthDue']) => {
    let monthOpt1Date = monthDueToTimestamp(monthOpt1);
    let monthOpt2Date = monthDueToTimestamp(monthOpt2);

    return monthOpt1Date.toDate().getTime() - monthOpt2Date.toDate().getTime();
}

function timestampToMonthDue(timestamp: firebase.firestore.Timestamp | undefined | null) {
    if (timestamp) {
        const date = timestamp.toDate();

        return dayjs(date).format("MMM 'YY");
    } else if (typeof timestamp === 'string') {
        console.log("STRING!");
        return timestamp;
    } else {
        return "DEC '99";
    }
}

function monthDueToTimestamp(monthDue: string | firebase.firestore.Timestamp | undefined | null) {
    if (monthDue) {
        if (typeof monthDue !== 'string') {
            monthDue = timestampToMonthDue(monthDue);
        }

        const parsedDateString = monthDue.replace("'", "20").replace(' ', ' 15 ');
        // save as a Firebase Timestamp: https://firebase.google.com/docs/reference/node/firebase.firestore.Timestamp
        return new firebase.firestore.Timestamp(new Date(parsedDateString).getTime() / 1000, 0);
    } else {
        return new firebase.firestore.Timestamp(new Date("Dec 31 2099").getTime() / 1000, 0);
    }
}

function timestampOrDateToDate(date: TimestampOrDate, asString = true, format = 'YYYY-MM-DD') {
    let parsedDate;
    if (date instanceof Date) {
        parsedDate = date;
    } else {
        parsedDate = date.toDate();
    }

    if (asString) {
        return dayjs(parsedDate).format(format);
    } else {
        return parsedDate;
    }
}

function getMinOfArray(arr: number[]) {
    return arr.sort((a, b) => a - b)[0];
}

function getMaxOfArray(arr: number[]) {
    return arr.sort((a, b) => a - b)[0];
}

function getUniqueObjectsByKey(data: any, key: string) {
    // data = data.filter((dat: any) => dat[key]);

    return Object.values(
        data.reduce((acc: any, cv: any) => {
            // if (!acc[cv.name.toLowerCase()]) acc[cv.name.toLowerCase()] = cv;
            // return acc;

            const k = cv[key]?.toLowerCase().trim();
            if (!acc[k]) {
                acc[k] = cv
            }
            return acc;
        }, {})
    );
}

function findChangedObjectKeys(o1: any, o2: any) {
    return Object.keys(o2).reduce((diff, key) => {
        if (o1[key] === o2[key]) return diff
        return {
            ...diff,
            [key]: o2[key]
        }
    }, {});
}

function convertJsonToFirestoreTimestamp(jsonTimestamp: { seconds: number, nanoseconds: number }) {
    return new firebase.firestore.Timestamp(jsonTimestamp.seconds, jsonTimestamp.nanoseconds)
}

async function sleep(milliseconds: number) {
    return new Promise((resolve) => setTimeout(resolve, milliseconds));
}

const grailUtil = {
    abbreviateNumber: abbreviateNumber,
    createMonthOptions: createMonthOptions,
    debounce: debounce,
    getCurrentMonthDue: getCurrentMonthDue,
    compareDates: compareDates,
    getCurrencySymbol: getCurrencySymbol,
    getExchangeRate: getExchangeRate,
    getLocalPrices: getLocalPrices,
    getMinOfArray: getMinOfArray,
    getMaxOfArray: getMaxOfArray,
    getPrice: getPrice,
    getDefaultGuidePrice: getDefaultGuidePrice,
    isSongPriceDomain: isSongPriceDomain,
    removeParamsFromURL: removeParamsFromURL,
    sortMonthOptions: sortMonthOptions,
    deepEqual: deepEqual,
    findChangedObjectKeys: findChangedObjectKeys,
    timestampOrDateToDate: timestampOrDateToDate,
    timestampToMonthDue: timestampToMonthDue,
    convertJsonToFirestoreTimestamp: convertJsonToFirestoreTimestamp,
    monthDueToTimestamp: monthDueToTimestamp,
    getUniqueObjectsByKey: getUniqueObjectsByKey,
    sleep: sleep,
}
export default grailUtil;