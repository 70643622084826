import { useRef } from "react";
import { useWindowVirtualizer } from "@tanstack/react-virtual";
import TalentCard from "./TalentCard";
import RosterTalentCard from "./RosterTalentCard";
import { CampaignType, Currency, Platform, SelectedTalent, Talent } from "../shared/types";

interface TalentListComponent {
    talent: Talent[],
    isManager?: boolean,
    add?: (id: string, selectedTalentItem: SelectedTalent["id"]) => void,
    remove?: (id: string) => void,
    selectedTalent?: SelectedTalent,
    currency: Currency,
    campaignType: CampaignType,
    platform: Platform,
    rosterMode?: boolean,
    lockTalentCards?: boolean,
}

export default function TalentList(props: TalentListComponent) {
    const { talent, isManager, add, remove, selectedTalent, currency, campaignType, platform, rosterMode, lockTalentCards } = props;

    const listRef = useRef<HTMLDivElement | null>(null);
    const count = talent.length;

    const virtualizer = useWindowVirtualizer({
        count: count,
        estimateSize: () => 352,
        overscan: 15,
        scrollMargin: listRef.current?.offsetTop ?? 0,
    });

    return (
        <>
            {/* <button
                onClick={() => {
                    virtualizer.scrollToIndex(0)
                }}
            >
                scroll to the top
            </button>
            <span style={{ padding: '0 4px' }} />
            <button
                onClick={() => {
                    virtualizer.scrollToIndex(count / 2)
                }}
            >
                scroll to the middle
            </button>
            <span style={{ padding: '0 4px' }} />
            <button
                onClick={() => {
                    virtualizer.scrollToIndex(count - 1)
                }}
            >
                scroll to the end
            </button>
            <hr /> */}


            {talent.length ? (
                <div ref={listRef} className="List">
                    <div
                        style={{
                            height: `${virtualizer.getTotalSize()}px`,
                            width: '100%',
                            position: 'relative',
                        }}
                    >
                        {virtualizer.getVirtualItems().map((item) => (
                            <div
                                key={talent[item.index].id} // don't use item.key for this, or bid values persist at their index for some reason
                                className={item.index % 2 ? 'ListItemOdd' : 'ListItemEven'}
                                ref={virtualizer.measureElement}
                                data-index={item.index}
                                style={{
                                    position: 'absolute',
                                    top: 0,
                                    left: 0,
                                    width: '100%',
                                    // height: `${item.size}px`,
                                    transform: `translateY(${item.start - virtualizer.options.scrollMargin
                                        }px)`,
                                }}
                            >
                                {!rosterMode && talent[item.index] ? (
                                    <TalentCard
                                        tal={talent[item.index]}
                                        isManager={isManager}
                                        add={add}
                                        remove={remove}
                                        selectedTalent={selectedTalent}
                                        currency={currency}
                                        campaignType={campaignType}
                                        platform={platform}
                                        isChecked={(selectedTalent || {})[talent[item.index].id] ? true : false}
                                        isLocked={lockTalentCards}
                                        key={`${talent[item.index].id}-${platform}`}
                                    // isRec={filter?.list === 'recommended'}
                                    />
                                ) : (
                                    <></>
                                )}

                                {rosterMode && talent[item.index] ? (
                                    <RosterTalentCard
                                        tal={talent[item.index]}
                                    />
                                ) : (<></>)}
                            </div>
                        ))}
                    </div>
                </div>
            ) : null}

        </>
    )
}