import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { createList, deleteList, useFreshLists } from "../services/firebase";
import { useQueryClient } from "@tanstack/react-query";
import { List } from "../shared/types";


//
import Layout from "../components/Layout";
import ModalStyle from '../theme/mui-styles/modal';

//
import Modal from '@mui/material/Modal';
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import EditIcon from '@mui/icons-material/Edit';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import DeleteIcon from '@mui/icons-material/Delete';
import IosShareIcon from '@mui/icons-material/IosShare';


export default function Lists() {
    const { uid } = useParams<{ uid: string }>();
    const navigate = useNavigate();
    const [deleteModalId, setDeleteModalId] = useState('');


    const listsQuery = useFreshLists(uid ? { uid } : { creatorRequest: true });
    const lists = listsQuery.flatData;

    const queryClient = useQueryClient();

    async function handleAdd() {
        const newList = {
            name: "New List",
            // talentIds: [],
            talent: {},
            uid
        };

        const res = await createList(newList);

        if (res.success) {
            console.log(res);

            navigate(`/edit-list/${res.result}`);
        } else {
            alert("An error occured, please try again");
            console.log(res.error);
        }
    }

    async function handleShare(listId: string) {
        const listUrl = `https://app.grail-talent.com/new-booking/${listId}`;
        await navigator.clipboard.writeText(listUrl);
        alert(`Your public list link has been copied to the clipboard:\n ${listUrl}`);
    }

    function handleEdit(listId: string) {
        navigate(`/edit-list/${listId}`);
    }

    async function handleDuplicate(list: List) {
        const newList = {
            name: list.name + " (Copy)",
            talentIds: list.talentIds || [],
            platform: list.platform || null,
            uid: list.uid,
        }

        const res = await createList(newList);

        if (res.success) {
            console.log(res);
            navigate(`/edit-list/${res.result}`);
        } else {
            alert("An error occured, please try again");
            console.log(res.error);
        }
    }

    async function handleDelete(listId: string) {
        const res = await deleteList(listId);

        if (res.success) {
            console.log(res);
            queryClient.resetQueries({ queryKey: ['lists', undefined, uid] });
            setDeleteModalId('');
        } else {
            console.log(res);
            alert("An error occured, please try again");
        }
    }

    return (
        <Layout gray={true}>
            <Modal open={!!deleteModalId} onClose={() => setDeleteModalId('')}>
                <Box sx={ModalStyle}>
                    <div className="font-weight-bold font-24 mb-100">Are You Sure You Want to Delete?</div>
                    <p className="">Are you sure you want to delete the <em>{lists.find((list) => list.id === deleteModalId)?.name || deleteModalId}</em> list?</p>
                    <p>This will permanently delete the list and <strong>can NOT be undone.</strong>The list link will stop working for any bookers who have it.</p>
                    <div className="flex">
                        <div className="mr-100 flex-1-0-auto"><button className="btn btn-block btn-outline-red btn-sm" onClick={() => handleDelete(deleteModalId)}>Delete List</button></div>
                        <div className="flex-1-0-auto"><button className="btn btn-block btn-primary btn-sm" onClick={() => setDeleteModalId('')}>Back to Safety</button></div>
                    </div>
                </Box>
            </Modal>
            <div className="p-100">
                <div className="flex justify-content-end mb-100">
                    <button onClick={handleAdd} className="btn btn-primary">+ New List</button>
                </div>
                {lists?.length ? lists.map((list) =>
                    <div className="card mb-50" key={list.id}>
                        <div className="flex align-items-center justify-content-between">
                            <div className="flex">
                                <div className="flex align-items-center">
                                    {list.platform === "TikTok" ? (<img className="true-color-icon mr-75" src="/icon-tiktok.svg" alt="TikTok" />) : null}
                                    {list.platform === "Instagram" ? (<img className="true-color-icon mr-75" src="/icon-instagram.svg" alt="Instagram" />) : null}
                                    {list.platform === "YouTube" ? (<img className="true-color-icon mr-75" src="/icon-youtube.svg" alt="YouTube" />) : null}
                                </div>
                                <div>
                                    <strong>{list.name}</strong>
                                    <div className="font-12">{list.talentIds?.length || (list.talent && Object.keys(list.talent).length)} Creators</div>
                                </div>
                            </div>

                            <div>
                                {/* <IconButton aria-label="share" onClick={() => handleShare(list.id)}>
                                    <IosShareIcon />
                                </IconButton>

                                <IconButton aria-label="edit" onClick={() => handleEdit(list.id)}>
                                    <EditIcon />
                                </IconButton>

                                <IconButton aria-label="duplicate" onClick={() => handleDuplicate(list)}>
                                    <ContentCopyIcon />
                                </IconButton>
                                <IconButton aria-label="delete" onClick={() => setDeleteModalId(list.id)}>
                                    <DeleteIcon />
                                </IconButton> */}

                                <Button color="error" aria-label="share" onClick={() => handleShare(list.id)} startIcon={<IosShareIcon />}>
                                    Share
                                </Button>

                                <Button color="error" aria-label="edit" onClick={() => handleEdit(list.id)} startIcon={<EditIcon />}>
                                    Edit
                                </Button>

                                <Button color="error" aria-label="duplicate" onClick={() => handleDuplicate(list)} startIcon={<ContentCopyIcon />}>
                                    Duplicate
                                </Button>

                                <Button color="error" aria-label="delete" onClick={() => setDeleteModalId(list.id)} startIcon={<DeleteIcon />}>
                                    Delete
                                </Button>
                            </div>
                        </div>
                    </div>
                ) : <div className="text-center py-400 px-100">You don't have any lists yet... why not add a new one?</div>}
            </div>
        </Layout>
    );
}