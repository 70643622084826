import { Hit } from '@algolia/client-search';
import { GridColumnVisibilityModel, GridFilterModel, GridPinnedColumns, GridSortModel } from "@mui/x-data-grid-pro";
import dayjs from "dayjs";
import firebase from "firebase/app";


// BASE LEVEL TYPES:
export interface Success { success: true, result: any };
export interface Failure { success: false, error: any };
export type Result = Success | Failure;
export type TimeUnits = dayjs.UnitTypeShort | dayjs.UnitTypeLong | dayjs.UnitTypeLongPlural;

export type Currency = "USD" | "GBP";
export type CampaignType = "Song" | "Brand";
export type Platform = "TikTok" | "Instagram" | "YouTube";


// export const PriceKeys = [
//     "brandPriceInstagramPost",
//     "brandPriceInstagramReel",
//     "brandPriceInstagramStory",
//     "brandPriceTikTok",
//     "brandPriceYouTubeIntagration",
//     "brandPriceYouTubePreroll",
//     "brandPriceYouTubeShort",
//     "brandPriceYouTubeVideo",
//     "songPriceInstagram",
//     "songPriceTikTok",
//     "songPriceYouTube",
// ] as const;
// export type Prices = {
//     [K in typeof PriceKeys[number]]?: number
// }

export interface Prices {
    brandPriceInstagramPost?: number | null,
    brandPriceInstagramReel?: number | null,
    brandPriceInstagramStory?: number | null,

    brandPriceTikTok?: number | null,

    brandPriceYouTubeIntegration?: number | null,
    brandPriceYouTubePreroll?: number | null,
    brandPriceYouTubeShort?: number | null,
    brandPriceYouTubeVideo?: number | null,

    songPriceInstagram?: number | null,
    songPriceTikTok?: number | null,
    songPriceYouTube?: number | null,
};

export type TimestampOrDate = firebase.firestore.Timestamp | Date;

export interface FireFilter {
    key: string,
    operator: firebase.firestore.WhereFilterOp,
    value: unknown,
}

export interface FileObject {
    url: string,
    fileName?: string,
}

export type ShortMonth = "Jan" | "Feb" | "Mar" | "Apr" | "May" | "Jun" | "Jul" | "Aug" | "Sep" | "Oct" | "Nov" | "Dec";

export interface DocMeta {
    createTime?: firebase.firestore.Timestamp,
    updateTime?: firebase.firestore.Timestamp,
    lastKnownUpdateUser?: string | null,
    lastKnownUpdateTime?: firebase.firestore.Timestamp,
}

export interface ClientWideSettings {
    currency?: Currency,
    paymentTerms?: {
        termsAccepted?: boolean,
        firstName?: string,
        lastName?: string,
        userId?: string,
        dateAccepted?: firebase.firestore.Timestamp | Date,
    },
    batchInvoices?: boolean,
}

// 
export const bookingStatusOptions = ["confirmed", "declined", "draft", "posted", "approved", "cancelled"] as const;

export interface Address {
    addressLine1: string,
    addressLine2?: string,
    city: string,
    state: string,
    postCode: string
    country: string
}

export interface SelectedTalent {
    [id: string]: {
        price?: number,
        notes?: string,
        isRec?: boolean,
    }
    // { id: string, bidPrice: number, isRec?: boolean, notes?: string }
};

// DOCUMENT OBJECT TYPES
export type GrailDoc = Booking | Campaign | Client | Talent | User;

export interface Booking {
    id: string,
    airtableBookerId?: string,
    airtableCampaignId?: string,
    airtableClientId?: string,
    airtableId?: string,
    airtableManagerIds?: string[],
    airtableSalesLeadId?: string,
    airtableType?: string,
    approvalDate?: TimestampOrDate,
    bookerId?: string,
    bookingType?: 'downpayment' | 'event' | 'cancelled campaign production fee';
    boostCode?: string,
    campaignId?: string,
    campaignType?: CampaignType,
    campaignPlatform?: Platform,
    clientId?: string,
    clientName?: string,
    contractFile?: FileObject,
    declineReason?: 'creator_decline' | 'booker_cancel' | 'relog';
    deleted?: boolean,
    lastUpdateByManager?: boolean,
    createdByManager?: boolean,
    creatorId?: string,
    currency?: Currency,
    docMeta?: DocMeta,
    invoiceLink?: string,
    invoiceNotes?: string,
    isTest?: boolean,
    lastUpdate?: 'AIRTABLE_SYNC' | 'WRITE_SYSTEM_IDS',
    managerIds?: string[],
    monthDue?: `${ShortMonth} '${number}` | string,
    monthDue2?: TimestampOrDate,
    platformForceSyncDate?: TimestampOrDate,
    price?: number,
    purchaseOrder?: string,
    overrideRemoveCommissions?: boolean | "all" | "manager", // OVERRIDE: "Expenses: Remove Commissions"
    overrideMicroRevenue?: number, // OVERRIDE: "Creator Commission Override"
    overrideManagerCommission?: number, // OVERRIDE: "Manager Commission Override"
    salesLeadId?: string,
    status?: typeof bookingStatusOptions[number] | null | ""; // null | "" | "new" | "confirmed" | "declined" | "draft" | "posted" | "approved" | "cancelled",
    talentId?: string,
    talentName?: string,
    talentPaymentSent?: boolean,
    talentPaymentSentDate?: TimestampOrDate,
    uniportEmail?: string,
    videoLink?: string,
}

export interface Campaign {
    id?: string,
    airtableId?: string,
    airtableDuplicate?: string, // TEMP UNTIL END OF AUGUST...
    booker?: {
        airtableBookerId?: string,
        company?: string,
        email?: string,
        firstName?: string,
        lastName?: string,
        phone?: string,
        uid?: string,
    },
    bookings?: {
        [id: string]: Booking
    },
    boostInterest?: boolean,
    campaignName?: string,
    campaignType?: CampaignType,  // migrating from: "Music" | "Brand",
    campaignPlatform?: Platform,
    lastUpdateByManager?: boolean,
    currency?: Currency,
    details?: string,
    docMeta?: DocMeta,
    file?: unknown, // synthetic...
    fileUrl?: string,
    invoiceDate?: TimestampOrDate,
    isTest?: boolean,
    originalQuote?: number,
    soundUrl?: string | null,
    talentReplacementsSent?: boolean,
    uid?: string,
    // company?: string,
    // created?: Date,
    // email?: string,
    // firstName?: string,
    // lastName?: string,
    // phone?: string,
    // stats2?: 
    // talent?: string[],
    // talentWithPrices: {}
    sendCampaignReceipt?: boolean,
    sendBookingOpp?: boolean,
}

export interface Client {
    id?: string,
    address?: Address,
    airtableId?: string,
    airtablePrimaryManagerId?: string,
    billingEmail?: string,
    billingPhone?: string,
    clientWideSettings?: ClientWideSettings,
    companySector?: "Agency" | "Charity" | "Consumer Accessories" | "Cosmetics, Make-Up & Beauty" | "E-Commerce" | "Fashion & Apparel" | "Film & TV" | "Financial Services" | "Food & Beverages" | "Health & Wellbeing" | "Record Label" | "Social Media" | "Video Games & Apps" | "Other",
    discountPercentage?: number,
    docMeta?: DocMeta,
    invoiceBothBookerAndClient?: boolean,
    name?: string,
    nameRegistered?: string,
    nameTrading?: string,
    netPaymentTerms?: number,
    orgNumber?: string,
    primaryBookerEmail?: string,
    primaryBookerId?: string,
    primaryManagerId?: string,
    requiresInvoiceNotes?: boolean,
    requiresPurchaseOrder?: boolean,
    requiresUniportEmail?: boolean,
    requiresAdminCC?: boolean,
    vatNumber?: string,
}

export interface Talent {
    id: string,
    birthday?: firebase.firestore.Timestamp | Date,
    brandPrice?: number,
    category?: string,
    cities?: string[],
    contentTags?: string[],
    country?: string,
    currency?: Currency,
    deleted?: boolean,
    defaultPrice?: number // "Synthetic" property calculated from localPrices, platform, etc...
    docMeta?: DocMeta,
    externalCommission?: number,
    failedTTCrawls?: number,
    hideUser?: boolean,
    igFollowers?: number,
    imgUrl?: string,
    instagramUser?: string,
    lastSynced?: TimestampOrDate, // | Date
    lastUpdate?: string,
    lists?: string[],
    listCustomPrice?: number, // "Synthetic" property from custom lists
    localPrices?: Prices, // "Synthetic" property calculated from currency, prices
    managers?: {
        airtableId?: string,
        id?: string,
        firstName?: string,
        lastName?: string,
        email?: string,
        phone?: string,
    }[],
    manualImgUrl?: string,
    name?: string,
    prices?: Prices,
    showOnWebsite?: boolean,
    songPrice?: number,
    syncedToAlgolia?: boolean,
    tiktokUser?: string,
    ttFollowers?: number,
    ttStats?: {
        averageViews?: number,
        engagementRate?: number,
        sourceData?: {
            diggCount?: number,
            shareCount?: number,
            commentCount?: number,
            playCount?: number,
        }[],
        lastUpdated?: TimestampOrDate,
    },
    // price?: number // "Synthetic" property calculated from currency, brandPrice, songPrice...
    managerIds?: string[],
    airtableManagerIds?: string[],
    // ytFollowers?: number,
    youtubeLink?: string,
    youtubeUser?: string, // YouTube Channel Handle
    ytFollowers?: number,
}

export interface User {
    id?: string,
    airtableBookerId?: string,
    airtableClientId?: string,
    bookerSelfRegistered?: boolean | null,
    hideBooker?: boolean | null,
    clientId?: string,
    campaignType?: CampaignType | "Multi",
    clientWideSettings?: ClientWideSettings,
    company?: string,
    docMeta?: DocMeta & { lastSignInTime?: firebase.firestore.Timestamp },
    email?: string,
    firstName?: string,
    lastName?: string,
    managerAirtableId?: string, // TJN TO-DO... rename to airtableManagerId
    managerBookingColumns?: GridColumnVisibilityModel,
    managerBookingFilters?: GridFilterModel,
    managerBookingSorting?: GridSortModel,
    managerBookingPinnedColumns?: GridPinnedColumns,
    phone?: string,
    orgDomain?: string,
    // showSongPrice?: boolean, // synthetic
    currency?: Currency,
    roles?: {
        admin?: boolean,
        booker?: boolean,
        brandTeam?: boolean,
        manager?: boolean,
        externalManager?: boolean,
        salesTeam?: boolean,
    },
    verified?: boolean,
}

// MISC
export interface TalFilter {
    campaignPlatform: Platform,
    priceRange: {
        lower: number,
        upper: number
    },
    ttFollowersRange: {
        lower: number,
        upper: number
    },
    cities: string[],
    contentTags: string[],
    countries: string[],
    search: string,
    listId: string,
    sortBy: string,
    tagFilterLogic: string,
    campaignType: CampaignType,
    currency: Currency
    searchOnly?: boolean,
    adminMode?: boolean,
    managerMode?: boolean,
    filterIndex?: number,

    showCampaignTypeSelect?: boolean,

    lockCampaignPlatform?: boolean,
    lockCampaignType?: boolean,
    lockCurrencyFromBooker?: boolean,
    lockCurrencyFromList?: boolean,
    lockTalentCards?: boolean,
}

export interface City {
    id?: string,
    docMeta?: DocMeta,
    city: string,
}

export interface Country {
    id?: string,
    alternateCode?: string, // Alt2
    code: string, // Alt1
    docMeta?: DocMeta
    label: string, // Primary
    liveOnAirtable?: string,
    phone?: string,
    suggested?: boolean,
}

export interface Tag {
    id?: string,
    docMeta?: DocMeta,
    tag: string,
}

export interface AutocompleteOption<T> {
    val: T | undefined,
    label: string,
    inputValue?: string,
}

export interface List {
    id: string,
    active: boolean,
    campaignType?: CampaignType,
    currency?: Currency,
    docMeta?: DocMeta,
    name: string,
    talentIds?: string[],
    talent?: SelectedTalent,
    platform?: Platform | null,
    creatorRequest?: boolean,
    uid?: string,
    useCustomPrices?: boolean,
}

// Algolia
export interface AlgoliaCreator {
    _newScore: number,
    _score: number,
    _tags?: string[],
    deleted?: boolean,
    hideUser?: boolean,
    id: string,
    instagramUser?: string,
    objectId: string,
    sureThing?: boolean,
    tiktokUser?: string,
    songPrice?: number,
    brandPrice?: number,
    currency?: Currency,
    country?: string,
    ttStats?: {
        averageViews?: number,
        engagementRate?: number,
        sourceData?: {
            diggCount?: number,
            shareCount?: number,
            commentCount?: number,
            playCount?: number,
        }[],
    },
}

export interface RecommendQueryParams {
    campaignType: CampaignType,
    minPrice: number,
    maxPrice: number,
    minAverageViews: number,
    talentIds: string[],
    previousRecs: Hit<AlgoliaCreator>[],
    uniqueTalentCountries: string[],
}

export type CreatorRecommendationList = Hit<AlgoliaCreator>[];